import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: resolve => require(['@/views/order/index.vue'], resolve),
  },
  {
    path: '/setting',
    component: resolve => require(['@/views/order/setting.vue'], resolve),
  },
  {
    path: '/login',
    component: resolve => require(['@/views/order/login.vue'], resolve),
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash', // 去掉url中的#
  routes
})

export default router
