

const user = {
  state: {
    roles: [],
  },
  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
  },
  actions: {
    remove({ commit }) {
      commit('SET_ROLES', [])
    },
    set_ROLES({ commit }, data) {
      commit('SET_ROLES', data)
    }
  }
}
export default user