import axios from 'axios';
import qs from 'qs';
import router from './../router'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
export default {
  install: Vue => {
    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    axios.defaults.timeout = 60000000; //请求超时
    const no_login_code = -1; //未登录返回的状态码
    const success_code = 1; //成功返回的状态码
    const err_code = 0; //失败返回的状态码
    axios.defaults.baseURL = `${process.env.VUE_APP_BASE_API}/api/v1`;
    Vue.prototype.httpPath = process.env.VUE_APP_BASE_API;
    Vue.prototype.wsPath = '';//WebSocket

    Vue.prototype.$get = params => {
      let data = {
        params: Object.assign({}, params.data),
        headers: { 'authorization': `Bearer ${getToken() || ''}` }
      }
      axios.get(params.url, data).then(res => {
        if (res.data.code == no_login_code) {
          removeToken()
          router.replace('login')
        } else if (res.data.code == success_code) {
          if (params.success)
            params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip('val')
            Message({
              message: res.data.msg || res.data.message,
              type: 'error'
            })
          } else if (params.tips) {
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('err', err)
        if (err.response.status == '404') {
          Message({ message: 'error:404', type: 'error' })
        } else if (err.response.status == '500') {
          params.tip('val')
          Message({ message: 'errpr:500', type: 'error' })
        } else if (params.fail)
          params.fail(err);
      })
    }

    Vue.prototype.$post = params => {
      let $axios;
      let config = { headers: { 'authorization': `Bearer ${getToken() || ''}` } };
      if (params.upload) {
        config = { headers: { 'Content-Type': 'multipart/form-data', 'authorization': `Bearer ${getToken() || ''}` } }
        $axios = axios.post(params.url, params.data, config)
      } else {
        let data = qs.stringify(Object.assign({}, params.data,))
        $axios = axios.post(params.url, data, config)
      }

      $axios.then(res => {
        if (res.data.code == no_login_code) {
          removeToken()
          router.replace('login')
        } else if (res.data.code == success_code) {
          if (params.success)
            params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip('val')
            Message({ message: res.data.msg || res.data.message, type: 'error' })
          } else if (params.tips) {
            params.tips(res.data)
          }
        } else if (res.data.code == 2) {
          if (params.showtip) {
            params.showtip(res.data)
          }
        }
      }).catch(err => {
        console.log('err', err)
        if (err.response.status == '404') {
          Message({ message: 'err:404', type: 'error' })
        } else if (err.response.status == '500') {
          Message({ message: 'err:500', type: 'error' })
        } else if (params.fail)
          params.fail(err);
      })
    }

    Vue.prototype.$down = params => {
      let $axios;
      let headers = { 'authorization': `Bearer ${getToken() || ''}` }
      let data = qs.stringify(Object.assign({}, params.data))
      $axios = axios.post(params.url, data, { headers, responseType: params.responseType, "Content-Type": "application/x-www-form-urlencoded" })
      $axios.then(res => {
        if (params.responseType == 'text') {
          if (res.data.code == success_code) {
            if (params.success)
              params.success(res.data);
          } else if (res.data.code == err_code) {
            if (params.tip) {
              params.tip('val')
              Message({ message: res.data.msg, type: 'error' })
            } else if (params.tips) {
              params.tips(res.data)
            }
          }
        } else {
          if (params.success)
            params.success(res);
        }
      }).catch(err => {
        console.log(32323, err);
      })
    }
  }
}