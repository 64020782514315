import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getter'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user
  },
  getters,
  plugins: [persistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        user: val.user
      }
    }
  })]
})
export default store
